import {
    AttributeEditor,
    Button,
    Container,
    ContentLayout,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Textarea,
} from "@cloudscape-design/components";
import { Formik, FormikErrors } from "formik";
import { FunctionComponent } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ObjectSchema } from "yup";
import { useCreateFeatureFlagMutation } from "../../api/effApi";
import { validatePermissions } from "../../util/permissionsUtil";
import ApiErrorAlert from "../common/ApiErrorAlert";
import Debug from "../common/Debug";
import WordListInput from "../common/WordListInput";

type FormState = {
    idPrefix: string;
    displayName: string;
    description: string;
    owners: string[];
    operators: string[];
    nonProdEnvironments: Environment[];
};

type Environment = {
    id: string;
    displayName: string;
};

const initialValues: FormState = {
    idPrefix: "",
    displayName: "",
    description: "",
    owners: [],
    operators: [],
    nonProdEnvironments: [
        {
            id: "non-prod",
            displayName: "Non-Prod",
        },
    ],
};

const schema: ObjectSchema<Omit<FormState, "owners" | "operators">> = Yup.object().shape({
    idPrefix: Yup.string()
        .required("Required")
        .matches(/^[A-Z0-9][A-Z0-9_]*$/, "Must be uppercase alphanumeric characters with underscores"),
    displayName: Yup.string().required("Required").max(80, "Must be 80 characters or less"),
    description: Yup.string().ensure().max(1000, "Must be 1000 characters or less"),
    nonProdEnvironments: Yup.array()
        .of(
            Yup.object().shape({
                id: Yup.string()
                    .required("Required")
                    .max(32, "Must be 32 characters or less")
                    .matches(/^[a-z0-9][a-z0-9-]*[a-z0-9]?$/, "Must be lowercase alphanumeric characters with dashes")
                    .notOneOf(["prod"], "Cannot be prod"),
                displayName: Yup.string()
                    .required("Required")
                    .max(64, "Must be 64 characters or less")
                    .transform(value => value.toLowerCase())
                    .notOneOf(["prod"], "Cannot be prod"),
            }),
        )
        .required("Required")
        .min(1, "At least 2 environments required"),
});

const CreateFeatureFlagPage: FunctionComponent = () => {
    const [createFeatureFlag, { error }] = useCreateFeatureFlagMutation();
    const user = useAuth().user!.profile.sub;
    const navigate = useNavigate();

    return (
        <ContentLayout header={<Header variant="h2">Create Feature Flag</Header>}>
            <Container>
                <Formik
                    initialValues={{
                        ...initialValues,
                        owners: [user],
                    }}
                    initialTouched={{
                        nonProdEnvironments: [{}],
                    }}
                    onSubmit={async values => {
                        const createResponse = await createFeatureFlag({
                            idPrefix: values.idPrefix.replace(/_$/, ""),
                            displayName: values.displayName,
                            description: values.description,
                            owners: values.owners,
                            operators: values.operators,
                            nonProdEnvironments: values.nonProdEnvironments,
                        });
                        if ("data" in createResponse) {
                            navigate(`/feature-flags/${createResponse.data.id}`);
                        }
                    }}
                    validate={values => {
                        const permissionsErrors = validatePermissions({
                            owners: values.owners,
                            operators: values.operators,
                        });

                        return {
                            ...permissionsErrors,
                        };
                    }}
                    validationSchema={schema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        setTouched,
                        submitForm,
                        isSubmitting,
                    }) => {
                        const handleChange = (field: keyof FormState, value: FormState[keyof FormState]) => {
                            setFieldTouched(field, true, false);
                            setFieldValue(field, value, true);
                        };

                        return (
                            <Form
                                actions={
                                    <Button variant="primary" onClick={() => submitForm()} loading={isSubmitting}>
                                        Create
                                    </Button>
                                }
                            >
                                <SpaceBetween size="l">
                                    <FormField
                                        label="ID Prefix"
                                        constraintText="Required"
                                        description="The identifier for the feature flag. A random numeric suffix will be appended to this value for the final ID."
                                        errorText={touched.idPrefix && errors.idPrefix}
                                    >
                                        <Input
                                            name="idPrefix"
                                            value={values.idPrefix}
                                            onChange={e =>
                                                handleChange(
                                                    "idPrefix",
                                                    e.detail.value
                                                        ?.toUpperCase()
                                                        ?.replace(/[ -]/g, "_")
                                                        ?.replace(/[^A-Z0-9_]/g, "") ?? "",
                                                )
                                            }
                                        />
                                    </FormField>

                                    <FormField
                                        label="Display Name"
                                        constraintText="Required"
                                        errorText={touched.displayName && errors.displayName}
                                        description="The display friendly name for the feature flag."
                                    >
                                        <Input
                                            name="displayName"
                                            value={values.displayName}
                                            onChange={e => handleChange("displayName", e.detail.value)}
                                        />
                                    </FormField>

                                    <FormField
                                        label="Description"
                                        errorText={touched.description && errors.description}
                                        constraintText="Optional"
                                    >
                                        <Textarea
                                            name="description"
                                            value={values.description}
                                            onChange={e => handleChange("description", e.detail.value)}
                                        />
                                    </FormField>

                                    <FormField
                                        label="Owners"
                                        description="Users that should have full control over the feature flag."
                                        errorText={touched.owners && errors.owners}
                                        constraintText="At least 1 required"
                                    >
                                        <WordListInput
                                            values={values.owners}
                                            onChange={owners => handleChange("owners", owners)}
                                        />
                                    </FormField>

                                    <FormField
                                        label="Operators"
                                        description="Users that should be able to dialup, dialdown, and modify overrides for the feature flag."
                                        errorText={touched.operators && errors.operators}
                                        constraintText="Optional"
                                    >
                                        <WordListInput
                                            values={values.operators}
                                            onChange={operators => handleChange("operators", operators)}
                                        />
                                    </FormField>

                                    <FormField
                                        label="Environments"
                                        description="The different environments that the feature flag will be able to be independently controlled in."
                                        errorText={
                                            typeof errors.nonProdEnvironments === "string" && errors.nonProdEnvironments
                                        }
                                    >
                                        <AttributeEditor
                                            items={[...values.nonProdEnvironments, { id: "prod", displayName: "Prod" }]}
                                            definition={[
                                                {
                                                    label: "ID",
                                                    errorText: (item, index) =>
                                                        touched.nonProdEnvironments?.[index]?.id &&
                                                        (errors.nonProdEnvironments as FormikErrors<Environment>[])?.[
                                                            index
                                                        ]?.id,
                                                    control: (item, index) => (
                                                        <Input
                                                            value={item.id}
                                                            readOnly={index === values.nonProdEnvironments.length}
                                                            placeholder="non-prod"
                                                            onChange={e => {
                                                                setFieldTouched(
                                                                    `nonProdEnvironments[${index}].id`,
                                                                    true,
                                                                    false,
                                                                );
                                                                setFieldValue(
                                                                    `nonProdEnvironments[${index}].id`,
                                                                    e.detail.value,
                                                                    true,
                                                                );
                                                            }}
                                                        />
                                                    ),
                                                },
                                                {
                                                    label: "Display Name",
                                                    errorText: (item, index) =>
                                                        touched.nonProdEnvironments?.[index]?.displayName &&
                                                        (errors.nonProdEnvironments as FormikErrors<Environment>[])?.[
                                                            index
                                                        ]?.displayName,
                                                    control: (item, index) => (
                                                        <Input
                                                            value={item.displayName}
                                                            readOnly={index === values.nonProdEnvironments.length}
                                                            placeholder="Non-Prod"
                                                            onChange={e => {
                                                                setFieldTouched(
                                                                    `nonProdEnvironments[${index}].displayName`,
                                                                    true,
                                                                    false,
                                                                );
                                                                setFieldValue(
                                                                    `nonProdEnvironments[${index}].displayName`,
                                                                    e.detail.value,
                                                                    true,
                                                                );
                                                            }}
                                                        />
                                                    ),
                                                },
                                            ]}
                                            onAddButtonClick={() => {
                                                setTouched(
                                                    {
                                                        ...touched,
                                                        nonProdEnvironments: [...touched.nonProdEnvironments!, {}],
                                                    },
                                                    false,
                                                );
                                                setFieldValue(
                                                    `nonProdEnvironments[${values.nonProdEnvironments.length}]`,
                                                    { id: "", displayName: "" },
                                                    true,
                                                );
                                            }}
                                            onRemoveButtonClick={({ detail: { itemIndex } }) => {
                                                setTouched(
                                                    {
                                                        ...touched,
                                                        nonProdEnvironments: touched.nonProdEnvironments!.toSpliced(
                                                            itemIndex,
                                                            1,
                                                        ),
                                                    },
                                                    false,
                                                );
                                                setFieldValue(
                                                    "nonProdEnvironments",
                                                    values.nonProdEnvironments.toSpliced(itemIndex, 1),
                                                    true,
                                                );
                                            }}
                                            addButtonText="Add Environment"
                                            removeButtonText="Remove"
                                            disableAddButton={values.nonProdEnvironments.length >= 10}
                                            isItemRemovable={item => item.id !== "prod"}
                                        />
                                    </FormField>

                                    <ApiErrorAlert error={error} />

                                    <Debug value={{ values, errors, touched }} />
                                </SpaceBetween>
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
        </ContentLayout>
    );
};

export default CreateFeatureFlagPage;
