import { createBrowserRouter } from "@datadog/browser-rum-react/react-router-v6";
import React, { FunctionComponent } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import store from "../state/store";
import Auth from "./Auth";
import { ErrorBoundary } from "./common/ErrorBoundary";
import CreateFeatureFlagPage from "./pages/CreateFeatureFlagPage";
import FeatureFlagDetailPage from "./pages/FeatureFlagDetailPage";
import FeatureFlagEnvDetailPage from "./pages/FeatureFlagEnvDetailPage";
import HomePage from "./pages/HomePage";
import Root from "./Root";

const router = createBrowserRouter([
    {
        path: "*",
        element: <Root />,
        children: [
            { index: true, element: <HomePage /> },
            { path: "feature-flags/create", element: <CreateFeatureFlagPage /> },
            { path: "feature-flags/:id", element: <FeatureFlagDetailPage /> },
            { path: "feature-flags/:id/env/:envId", element: <FeatureFlagEnvDetailPage /> },
        ],
    },
]);

const App: FunctionComponent = () => {
    return (
        <ErrorBoundary>
            <Auth onSignInNavigate={path => router.navigate(path, { replace: true })}>
                <ReduxProvider store={store}>
                    <RouterProvider router={router} />
                </ReduxProvider>
            </Auth>
        </ErrorBoundary>
    );
};

export default App;
