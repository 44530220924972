import { datadogRum } from "@datadog/browser-rum";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import { AuthProvider, AuthProviderProps, useAuth } from "react-oidc-context";
import {
    SERVICEAUTH_AUTHORITY,
    SERVICEAUTH_CLIENT_ID,
    SERVICEAUTH_REDIRECT_URI,
    SERVICEAUTH_SCOPE,
} from "../constants";
import { addShamefulSplitPolyfill } from "../util/shamefulSplitPolyfillUtil";

const oidcConfig: AuthProviderProps = {
    authority: SERVICEAUTH_AUTHORITY,
    client_id: SERVICEAUTH_CLIENT_ID,
    redirect_uri: SERVICEAUTH_REDIRECT_URI,
    response_type: "code",
    scope: `openid ${SERVICEAUTH_SCOPE}`,
    metadata: {
        authorization_endpoint: "https://weblogin.asu.edu/serviceauth/oauth2/code/allow",
        token_endpoint: "https://weblogin.asu.edu/serviceauth/oauth2/token",
        code_challenge_methods_supported: ["S256"],
        userinfo_endpoint: "https://weblogin.asu.edu/serviceauth/oauth2/oidc/user-info",
        end_session_endpoint: "https://weblogin.asu.edu/cas/logout",
    },
};

export type AuthProps = React.PropsWithChildren & {
    onSignInNavigate: (originalPath: string) => void;
};

const Auth: FunctionComponent<AuthProps> = props => {
    addShamefulSplitPolyfill();

    return (
        <AuthProvider
            {...oidcConfig}
            onSigninCallback={user => {
                if (user) {
                    datadogRum.setUser({
                        id: user.profile.sub,
                        ...(user.profile.email ? { email: user.profile.email } : {}),
                    });
                }
                const path = (user?.state as Record<string, string>)?.path ?? "/";
                props.onSignInNavigate(path);
            }}
            onRemoveUser={() => {
                datadogRum.clearUser();
            }}
        >
            <AuthHandler>{props.children}</AuthHandler>
        </AuthProvider>
    );
};

type AuthHandlerProps = React.PropsWithChildren;

const AuthHandler: FunctionComponent<AuthHandlerProps> = props => {
    const auth = useAuth();

    const signin = useCallback(async () => {
        await auth.signinRedirect({
            state: { path: window.location.pathname },
        });
    }, [auth]);

    useEffect(() => {
        if (!auth.isLoading && !auth.isAuthenticated) {
            signin();
        } else if (auth.error?.message === "Request was made for an invalid refresh token") {
            signin();
        }
    }, [auth, signin]);

    return props.children;
};

export default Auth;
