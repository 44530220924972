import { addReactError } from "@datadog/browser-rum-react";
import * as React from "react";

type ErrorBoundaryProps = React.PropsWithChildren;
type ErrorBoundaryState = {
    hasError: boolean;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
        addReactError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h2>Something went wrong</h2>
                    <p>Please try again later.</p>
                </div>
            );
        }

        return this.props.children;
    }
}
