import { datadogRum } from "@datadog/browser-rum";
import { reactPlugin } from "@datadog/browser-rum-react";
import { DD_APPLICATION_ID, DD_CLIENT_TOKEN, DD_ENV } from "./constants";

datadogRum.init({
    applicationId: DD_APPLICATION_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "eff-ui",
    env: DD_ENV,

    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    defaultPrivacyLevel: "mask-user-input",
    plugins: [reactPlugin({ router: true })],
});
